// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('./src/application.scss')
import './enable_direct_uploads'

import JQuery from 'jquery';
import 'popper.js'
import 'bootstrap'
import 'cookie-notice';
import bsCustomFileInput from 'bs-custom-file-input'
import toastr from 'toastr'
import socialshares from 'socialshares'

window.$ = window.JQuery = JQuery;
window.toastr = toastr

toastr.options = {
  "debug": false,
  "positionClass": "toast-top-full-width",
}

$(document).on("turbolinks:load", function() {
  // toastr.remove()
  bsCustomFileInput.init()

  socialshares.mount()
})

$(document).on("turbolinks:load", function() {
  $('.carousel').carousel({
    interval: 2500
  })
  gtag('config', 'UA-122651275-3', {'page_location': event.data.url});

})
